import React, { useEffect } from 'react';
import Layout from '../components/layout';
import { navigate } from 'gatsby-link';

export default () => {
  useEffect(() => {
    navigate('/');
  }, []);

  return <Layout>Not found</Layout>;
};
